<template>
    <span class="gst-chip-new">
        {{ $t( '_common:terms.new' ) }}
    </span>
</template>

<script>
    export default {
        name: 'ChipNew',
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-chip-new {
        line-height: 20px;
        padding: theme-spacing( 1 );
        background-color: theme-color( 'secondary' );
        color: theme-color( 'white' );
        font-size: font-size( 'xxxxxs' );
        font-weight: font-weight( 'large' );
        border-radius: border-radius( 'xxs' );
    }
</style>