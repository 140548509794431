<template>
    <div class="gst-hotel-offers-modal d-flex flex-column">
        <div class="gst-hotel-offers-modal__header d-flex flex-wrap mt-6 mb-4 ml-8">
            <ChipNew class="gst-hotel-offers-modal__chip mr-3 mb-2" />
            <PrismicRichText :field="title" class="gst-hotel-offers-modal__title" />
        </div>
        <v-divider />
        <PrismicRichText :field="content" class="gst-hotel-offers-modal__content" />
        <v-divider />
        <div class="gst-hotel-offers-modal__button-wrapper d-flex">
            <BaseButton
                :placeholder="buttonLabel"
                class="gst-hotel-offers-modal__button mt-5 mr-6 mb-5"
                color="primary"
                @click="$emit('close')" />
        </div>
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import ChipNew from '@tenant/app/components/chips/ChipNew';

    export default ( {
        name: 'HotelOffersModal',
        components: {
            BaseButton,
            ChipNew
        },
        props: {
            title: {
                type: Array,
                required: true
            },
            content: {
                type: Array,
                required: true
            },
            buttonLabel: {
                type: String,
                required: true
            }
        }
    } );
</script>

<style lang="scss">
@import "@scssMixins";
@import "@scssVariables";

.gst-hotel-offers-modal {
    height: auto;
    background-color: theme-color( 'white' );
    max-width: 858px;

    .gst-hotel-offers-modal__header {
        align-content: center;
        
        .gst-hotel-offers-modal__chip {
            font-size: font-size( 'xxs' );
            text-transform: uppercase;
            max-height: 31px;
            min-width: 45px;
            text-align: center;
        }

        .gst-hotel-offers-modal__title {
            h5 {
                line-height: line-height( 'xxxxxl' );
                font-size: font-size( 'xxxxl' );
                font-weight: font-weight( 'large' );
            }
        }
    }

    .gst-hotel-offers-modal__button-wrapper {
        justify-content: end;

        .gst-hotel-offers-modal__button {
            font-weight: font-weight( 'large' );
            text-transform: capitalize;
        }
    }

    .gst-hotel-offers-modal__content {
        padding: theme-spacing( 8 );
        padding-right: theme-spacing( 10 );
        font-size: font-size( 'xxl' );
        font-weight: font-weight( 'regular' );
        word-break: break-word;
    }
}

@include mobile-only {
    .gst-hotel-offers-modal {
        .gst-hotel-offers-modal__header {
            .gst-hotel-offers-modal__title {
                h5 {
                    line-height: 26px;
                    font-size: 21px;
                }
            }
        }

        .gst-hotel-offers-modal__content {
            p {
                font-size: font-size( 'l' );
                font-weight: font-weight( 'regular' );
            }
        }
    }
}
</style>
